import { useEffect, useState } from "react";
import UncontrolledTextArea from "../UncontrolledTextArea/UncontrolledTextArea";
import "./Table.scss";
import "./table.css";

const hoursToShade = [
  "22",
  22,
  23,
  "23",
  24,
  "24",
  0,
  "0",
  1,
  "1",
  2,
  "2",
  3,
  "3",
  4,
  "4",
  5,
  "5",
  6,
  "6",
];

const Table = ({ data: rawData, onTableChange }) => {
  const [tableState, setTableState] = useState({
    heading: "",
    data: [],
  });
  const [selectedRow, setSelectedRow] = useState(null); // Track the selected row for deletion


  // Parse the rawData and set it into state
  useEffect(() => {
    let allItems = JSON.parse(rawData.replace(/'/gi, '"'));
    const heading = allItems[0][0]; // The first element is the heading
    allItems.splice(0, 1); // Remove the heading from the data
    setTableState({ heading, data: allItems });
  }, [rawData]);
  useEffect(() => {
    // Function to handle the key press
    const handleKeyDown = (event) => {
      // Check if Ctrl + Z is pressed
      if (
        (event.ctrlKey && event.key === "z") ||
        (event.ctrlKey && event.key === "Z")
      ) {
        event.preventDefault(); // Prevent the default undo action
        handleAddRow(); // Call the addRow function
      }
      if (
        (event.ctrlKey && event.key === "d") ||
        (event.ctrlKey && event.key === "D")
      ) {
        event.preventDefault(); // Prevent the default undo action
        handleDeleteRow(selectedRow); // Call the deleteRow function
      }
    };

    // Add event listener for keydown
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedRow]);

  // Update table data when a cell is edited
  const handleCellChange = (rowIndex, colIndex, value) => {
    console.log();
    const updatedData = [...tableState.data];
    updatedData[rowIndex][colIndex] = value; // Update the specific cell value
    const updatedTableState = { ...tableState, data: updatedData };
    setTableState(updatedTableState);
    console.log(tableState);
    // Ensure onTableChange is a function before calling it
    if (typeof onTableChange === "function") {
      onTableChange(updatedTableState); // Pass the updated state to the parent
    } else {
      console.error("onTableChange is not a function");
    }
  };

  // Handle row click to select it for options (like add/delete)
  const handleRowClick = (index, e) => {
    e.stopPropagation(); // Prevent event bubbling
    const tableRect = e.currentTarget.parentNode.getBoundingClientRect(); // Get table dimensions
    const clickX = e.clientX;
    const clickY = e.clientY;

    let popupX = clickX - tableRect.left; // Position relative to the table
    let popupY = clickY - tableRect.top;

    // Adjust popup to stay within the table bounds
    const maxPopupX = tableRect.width - 100; // Assuming popup is 100px wide
    const maxPopupY = tableRect.height - 50; // Assuming popup is 50px tall

    popupX = Math.min(popupX, maxPopupX); // Prevent overflow on X-axis
    popupY = Math.min(popupY, maxPopupY); // Prevent overflow on Y-axis

    setSelectedRow(index === selectedRow ? null : index); // Toggle row selection
    // setPopupPosition({ top: popupY, left: popupX }); // Position popup inside the table
    // setPopupVisible(index !== selectedRow); // Show popup if a row is selected
  };

  // Handle row deletion
  const handleDeleteRow = (index) => {
    const updatedData = tableState.data.filter((_, i) => i !== index);
    setTableState({ ...tableState, data: updatedData });
    setSelectedRow(null); // Reset selection after deletion
    // setPopupVisible(false); // Hide popup after deletion
  };

  // Handle row addition
  const handleAddRow = () => {
    const newRow = Array(tableState.data[0].length).fill(""); // Create a new row with empty cells
    const updatedData = [...tableState.data, newRow];
    setTableState({ ...tableState, data: updatedData });
    // setPopupVisible(false); // Hide the popup after adding a row
  };

  // Handle saving the table data

  // Update the heading when it is changed
  const handleHeadingChange = (newHeading) => {
    const updatedTableState = { ...tableState, heading: newHeading };
    setTableState(updatedTableState);
    onTableChange(updatedTableState); // Pass the updated state to the parent
  };

  return (
    <div className="table">
      {/* Header Input */}
      <input
        className="table__header"
        type="text"
        value={tableState.heading}
        onChange={(e) => handleHeadingChange(e.target.value)}
        style={{
          border: "none",
          textAlign: "center",
          position: "relative",
          left: "50%",
          width: "190px",
          backgroundColor: "transparent",
          transform: "translate(-50%, -50%)",
          outline: "none",
          fontSize: "9px",
        }}
      />

      {/* Table */}
      <table className="table__table" style={{ marginTop: "-8px" }}>
        <thead>
          <tr>
            {/* Static column headers */}
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Hour" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="Beats" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Max HR" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Min HR" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Avg HR" />
            </th>
            <th style={{ width: "22px" }}>
              <UncontrolledTextArea defaultValue="Tachy (SR > 100)" />
            </th>
            <th style={{ width: "22px" }}>
              <UncontrolledTextArea defaultValue="Brady (SR < 60)" />
            </th>
            <th style={{ width: "15px" }}>
              <UncontrolledTextArea defaultValue="SP > 2 sec" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="V ectopy" />
            </th>
            <th style={{ width: "16px" }}>
              <UncontrolledTextArea defaultValue="V Pair" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="V Triplet" />
            </th>
            <th style={{ width: "30px" }}>
              <UncontrolledTextArea defaultValue="V Bigemni" />
            </th>
            <th style={{ width: "34px" }}>
              <UncontrolledTextArea defaultValue="V Trigemny" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="A Ectopy" />
            </th>
            <th style={{ width: "18px" }}>
              <UncontrolledTextArea defaultValue="A Pair" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="A Triplet" />
            </th>
            <th style={{ width: "33px" }}>
              <UncontrolledTextArea defaultValue="A Bigemny" />
            </th>
            <th style={{ width: "34px" }}>
              <UncontrolledTextArea defaultValue="A Trigemny" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="VT" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="SVT" />
            </th>
            <th>
              <UncontrolledTextArea defaultValue="AFib" />
            </th>
            <th style={{ width: "30px" }}>
              <UncontrolledTextArea defaultValue="Atrial Run" />
            </th>
            <th style={{ width: "38px" }}>
              <UncontrolledTextArea defaultValue="Ventricular Run" />
            </th>
          </tr>
        </thead>
        <tbody>
          {tableState.data.map((row, rowIndex) => (
            <tr
              key={tableState.heading + rowIndex}
              className={
                hoursToShade.includes(row[0]) ? "table__row-shade" : ""
              }
              onClick={(e) => handleRowClick(rowIndex, e)} // Pass event to handleRowClick
            >
              {row.map((dt, colIndex) => (
                <td key={tableState.heading + rowIndex + colIndex}>
                  <input
                    value={dt} // Controlled input
                    onChange={(e) =>
                      handleCellChange(rowIndex, colIndex, e.target.value)
                    } // Capture cell change
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Popup for Add and Delete */}
      {/* {popupVisible && selectedRow !== null && (
        <div
          className="table__row-popup"
          style={{
            top: popupPosition.top, // Use the updated top position
            left: popupPosition.left, // Use the updated left position
          }}
        >
          <button className="report__button" onClick={handleAddRow}>
            Add Row
          </button>
          <button
            className="report__button"
            onClick={() => handleDeleteRow(selectedRow)}
          >
            Delete Row
          </button>
        </div>
      )} */}
    </div>
  );
};

export default Table;
